<template>
  <div class="page">
    <div
      :style="`background: url(${bg}) no-repeat center center / cover;`"
      class="pricing-bg text-center w-100"
    >
      <h1 class="text-white">Bring your estimates to life. Get started today!</h1>
      <h3 class="text-white">Our dedicated customer success team is ready to support you.</h3>
    </div>

    <SignupForPackage :showSplash="true" class="subscriptions" />
  </div>
</template>

<script>
import SignupForPackage from '../billing/subscriptions/SignupForPackage.vue'
import BillingMixin from '../billing/BillingMixin'

import bg from '../../assets/pricing-bg.png'

export default {
  name: 'Pricing',
  components: {
    SignupForPackage
  },
  data() {
    return {
      bg
    }
  },
  mixins: [BillingMixin],
  methods: {}
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pricing-bg {
  padding: 5em 3em 12em 3em;
  @media (max-width: 768px) {
    padding: 3em;
  }
}
.subscriptions {
  margin-top: -10em;
  z-index: 99;
  @media (max-width: 768px) {
    margin-top: 0;
  }
}
.page {
  overflow-y: auto;
}
</style>
